import React, { createRef } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookOpen, faMobile, faLock, faImage, faSearch, faDownload, faBell, faCalendar } from '@fortawesome/free-solid-svg-icons'
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
//import Image from '../elements/Image';

const featuresRef = createRef();

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Features',
    paragraph: 'All the features you want without all of the clutter.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
      ref={featuresRef}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" />
          <div className={tilesClasses}>

          <div className="tiles-item reveal-from-bottom" data-reveal-delay="0">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <FontAwesomeIcon icon={faBookOpen} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Multiple Journals
                    </h4>
                  <p className="m-0 text-sm">
                   Separate journal entries into different journals, add cover photos, and set a custom color theme for each journal.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="0">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <FontAwesomeIcon icon={faMobile} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Multiple Devices
                    </h4>
                  <p className="m-0 text-sm">
                    Automatically sync data between all your devices. No need to transfer data manually. Keep journaling online and offline.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="0">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <FontAwesomeIcon icon={faLock} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Security
                    </h4>
                  <p className="m-0 text-sm">
                    Security is our #1 concern. All data is encrypted and you can enable a lock screen as an added layer of security.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="0">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <FontAwesomeIcon icon={faImage} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Photos
                    </h4>
                  <p className="m-0 text-sm">
                    Attach high quality photos to any journal entry. View the photos in each journal entry or in a gallery for the whole journal.
                    </p>
                </div>
              </div>
            </div>

            {/* <div className="tiles-item reveal-from-bottom" data-reveal-delay="0">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <FontAwesomeIcon icon={faMicrophone} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Audio Journals
                    </h4>
                  <p className="m-0 text-sm">
                    Attach high quality voice recordings to any journal entry so you can listen to your journal entries instead of reading them.
                    </p>
                </div>
              </div>
            </div> */}

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="0">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <FontAwesomeIcon icon={faSearch} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Search
                    </h4>
                  <p className="m-0 text-sm">
                    Quickly search through your journal entries by title, text, or date. No more scrolling through journal entries to find something specific.
                    </p>
                </div>
              </div>
            </div>

            {/* <div className="tiles-item reveal-from-bottom" data-reveal-delay="0">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <FontAwesomeIcon icon={faFont} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Text Formatting
                    </h4>
                  <p className="m-0 text-sm">
                    Robust journal entry formatting includes all the document writing features you are used to.
                    </p>
                </div>
              </div>
            </div> */}

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="0">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <FontAwesomeIcon icon={faBell} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Custom Reminders
                    </h4>
                  <p className="m-0 text-sm">
                    Set custom reminders for times you would like to journal. Never forget about journaling again.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="0">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <FontAwesomeIcon icon={faCalendar} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Calendar
                    </h4>
                  <p className="m-0 text-sm">
                    View all of your journal entries in a calendar to track your consistency or quickly find memorable moments.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="0">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <FontAwesomeIcon icon={faDownload} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Export
                    </h4>
                  <p className="m-0 text-sm">
                    Export journal entries to PDF with all the attached images and text included.
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;